import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase, { firestore, baseUrl } from '../utils/firebase'
import UserService from '../services/UserService'

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (dispatch, getState) => {
    return await fetch(`${baseUrl}/api/auth/signin`).then(
      (res) => res.json()
    );
  }
);
export const loginUser = createAsyncThunk(
  "users/login",
  async (postData, getState) => {
    const response = await UserService.login(postData)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const signupUser = createAsyncThunk(
  'users/signup',
  async (postData, { rejectWithValue }) => {
    console.log('signup user', postData)

    try {
      const response = await UserService.create(postData)
      console.log('save user response', response)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error')
    }
  }
)

export const updateUser = createAsyncThunk(
  'users/update',
  async (postData, { rejectWithValue }) => {
    console.log('updating user', postData)

    try {
      const response = await UserService.update(postData)
      console.log('updating user response', response)
      const data = await response.json()
      return data
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error')
    }
  }
)

export const logoutUser = createAsyncThunk(
  "users/logout",
  async (postData, getState) => {
    return true;
  }
);

export const getAllAdvertisers = createAsyncThunk(
  "users/advertisers",
  async (postData, getState) => {
    const response = await UserService.getAllAdvertisers()
    if(response.status===200){
      return response.data
    }
    return response
  }
);
export const getAllDrivers = createAsyncThunk(
  "users/drivers",
  async (postData, getState) => {
    const response = await UserService.getAllDrivers()
    if(response.status===200){
      return response.data
    }
    return response
  }
);

const usersSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    advertisers:[],
    drivers:[],
    me: null,
    loggedIn: false,
    loading: false,
    status: null,
  },
  extraReducers: {
    [getUsers.pending]: (state, action) => {
      state.status = "loading";
    },
    [getUsers.fulfilled]: (state, action) => {
      state.status = "success";
      state.users = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.status = "failed";
    },

    //users login
    [loginUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [loginUser.fulfilled]: (state, action) => {
      console.log('loging reducer', action.payload)
      if(action.payload && action.payload.id >0){
        state.status = "success";
        state.loggedIn = true;
        state.me = action.payload;
      }else{
        state.status = "failed";
        state.me = action.payload;
      }
    },
    [loginUser.rejected]: (state, action) => {
      state.status = "failed";
    },

    //users signup
    [signupUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [signupUser.fulfilled]: (state, action) => {
      state.status = "success";
      state.users = action.payload;
    },
    [signupUser.rejected]: (state, action) => {
      state.status = "failed";
    },

    //updating user
    [updateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = "success";
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = false
      state.status = "failed";
    },

    //logout
    [logoutUser.fulfilled]: (state, action) => {
      state.status = "failed";
      state.loggedIn = false;
      state.me = null;
    },

    [getAllAdvertisers.fulfilled]: (state, action) => {
      state.status = "success";
      state.advertisers = action.payload;
    },
    [getAllDrivers.fulfilled]: (state, action) => {
      state.status = "success";
      state.drivers = action.payload;
    },
  },
});

export default usersSlice.reducer;
