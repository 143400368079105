import axios from 'axios'
import firebase, { firestore, baseUrl } from '../utils/firebase'

const db = firestore.collection('regions')

const getSettings = () => {
  const response = axios.get(`${baseUrl}/api/settings/`)
  return response
}

const update = async (data) => {
  const response = await axios.put(`${baseUrl}/api/settings/update`, data)
  return response
}

const SettingsService = {
  getSettings,
  update,
}

export default SettingsService
