import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RegionService from '../services/RegionService'
import SettingsService from '../services/SettingsService'
import CompanyService from '../services/CompanyService'

export const getSettings = createAsyncThunk(
  "settings/get",
  async (dispatch, getState) => {
    const response = await SettingsService.getSettings();
    if(response.status===200){
      return response.data
    }
    return response
  }
)

export const updateSettings = createAsyncThunk(
  "settings/update",
  async (data, getState) => {
    const response = await SettingsService.update(data)
    if(response.status===200){
      return response.data
    }
    // return response
    return data
  }
);

const settingsSlice = createSlice({
  name: "region",
  initialState: {
    settings: [],
    status: null
  },
  extraReducers: {
    [getSettings.fulfilled]: (state, action) => {
      state.status = "success";
      state.settings = action.payload;
    },
    [updateSettings.fulfilled]: (state, action) => {
      state.status = "success";
    },
  },
});

export default settingsSlice.reducer;
