import axios from 'axios'
import firebase, { firestore, baseUrl } from '../utils/firebase'

const db = firestore.collection('regions')

const getAll = () => {
  const response = axios.get(`${baseUrl}/api/vehicle/list`)
  return response
}

const getByUser = (userId) => {
  const response = axios.get(`${baseUrl}/api/vehicle/list/${userId}/`)
  return response
}

const create = async (data) => {
  const response = await axios.post(`${baseUrl}/api/vehicle/save`, data)
  return response
}

const update = async (data) => {
  const {id} = data
  const response = await axios.put(`${baseUrl}/api/vehicle/${id}/update`, data)
  return response
}

const remove = async (id) => {
  const response = await axios.delete(`${baseUrl}/api/vehicle/${id}/delete`)
  return response
}

const pause = async (data) => {
  const {id} = data;
  const response = await axios.put(`${baseUrl}/api/vehicle/${id}/pause`, data)
  return response
}

const activate = async (data) => {
  const {id} = data;
  const response = await axios.put(`${baseUrl}/api/vehicle/${id}/activate`, data)
  // data.status = 1
  return response
}

const VehicleService = {
  getAll,
  getByUser,
  create,
  update,
  remove,
  pause,
  activate
}

export default VehicleService
