/* global google */
import React, { Fragment } from 'react'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle,
} from 'react-google-maps'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'

const Map = (props) => {
  const { zoom, center, places } = props

  return (
    <GoogleMap defaultZoom={zoom} defaultCenter={center}>
      {places.map((place) => (
        <Fragment key={place.id}>
          <MarkerWithLabel
            position={{
              lat: parseFloat(place.latitude),
              lng: parseFloat(place.longitude),
            }}
            labelAnchor={new google.maps.Point(place.latitude, place.longitude)}
            labelStyle={{
              backgroundColor: 'white',
              fontSize: '12px',
              padding: '5px',
            }}
          >
            <div>{place.label}</div>
          </MarkerWithLabel>
          {place.circle && (
            <Circle
              defaultCenter={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude),
              }}
              radius={place.circle.radius}
              options={place.circle.options}
            />
          )}
        </Fragment>
      ))}
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(Map))
