import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase, { firestore, baseUrl } from '../utils/firebase'
import UserService from '../services/UserService'
import CampaignService from '../services/CampaignService'
import SlotService from '../services/SlotsService'

export const getSlots = createAsyncThunk(
  "slots/getAll",
  async (dispatch, getState) => {
    const response = await SlotService.getAll()
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const getSlotsByTime = createAsyncThunk(
  "slots/getAll",
  async (time, getState) => {
    const response = await SlotService.getByTime(time)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const createSlot = createAsyncThunk(
  "slots/create",
  async (data, getState) => {
    const response = await SlotService.create(data)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const updateSlot = createAsyncThunk(
  "slots/update",
  async (data, getState) => {
    const response = await SlotService.update(data)
    if(response.status===200){
      // return response.data
    }
    // return response
    return data
  }
);

export const setSelectedCampaign = createAsyncThunk(
  "campaigns/select_campaign",
  async (data, getState) => {
    return data
  }
);

export const pauseCampaign = createAsyncThunk(
  "campaigns/pause",
  async (data, getState) => {
    const response = await CampaignService.pause(data)
    return response
  }
);

export const activateCampaign = createAsyncThunk(
  "campaigns/activate",
  async (data, getState) => {
    const response = await CampaignService.activate(data)
    return response
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaigns/activate",
  async (data, getState) => {
    const response = await CampaignService.remove(data)
    return response
  }
);


const slotSlice = createSlice({
  name: "slot",
  initialState: {
    slots: [],
    status: null,
  },
  extraReducers: {
    [getSlotsByTime.fulfilled]: (state, action) => {
      state.slots = action.payload
    },
  },
});

export default slotSlice.reducer;
