export const path = {
  login: '/login',
  signup: '/signup',
  signup_advertiser: '/signup/advertiser',
  signup_driver: '/signup/driver',
  profile: '/profile',
  resetPassword: '/reset-password',
  confirmEmail: '/confirm-email',
  dashboard: '/dashboard',
  add_campaign: '/campaigns/add',
  add_campaign_confirm: '/campaigns/confirm',
  edit_campaign: '/campaigns/:campaignId',
  campaigns: '/campaigns',
  campaign_pay: '/campaigns/pay/',
  single_vehicle: '/vehicle/:vehicleId/',
  withdraw: '/withdraw/:vehicleId/',
  withdraw_success: '/withdraw/:vehicleId/success/',
  withdrawal_history: '/withdraw_history/',
  //stats: '/stats/:vehicleId/',
  stats: '/stats/',
  admin: '/admin/',
  admin_advertisers: '/admin/advertisers/',
  admin_drivers: '/admin/drivers/',
  admin_slots: '/admin/slots/',
  admin_regions: '/admin/regions/',
  admin_companies: '/admin/companies/',
  admin_settings: '/admin/settings/',

  driver_view_update: '/driver/watch/',
}
