import axios from 'axios'
import firebase, { firestore, baseUrl } from '../utils/firebase'

const db = firestore.collection('regions')

const getAll = () => {
  const response = axios.get(`${baseUrl}/api/auth/list`)
  return response
}

const getAllAdvertisers = () => {
  const response = axios.get(`${baseUrl}/api/users/advertisers`)
  return response
}

const getAllDrivers = () => {
  const response = axios.get(`${baseUrl}/api/users/drivers`)
  return response
}

const create = async (data) => {
  const response = await axios.post(`${baseUrl}/api/auth/signup`, data)
  return response
}

const login = async (data) => {
  const response = await axios.post(`${baseUrl}/api/auth/signin`, data)
  return response
}

const update = async (data) => {
  const {id} = data
  const response = await axios.put(`${baseUrl}/api/auth/${id}/update`, data)
  return response
}

const remove = async (id) => {
  const response = await axios.delete(`${baseUrl}/api/auth/${id}/delete`)
  return response
}

const UserService = {
  getAll,
  getAllAdvertisers,
  getAllDrivers,
  create,
  update,
  remove,
  login,
}

export default UserService
