import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RegionService from '../services/RegionService'

export const getRegions = createAsyncThunk(
  "regions/getRegions",
  async (dispatch, getState) => {
    const response = await RegionService.getAll();
    if(response.status===200){
      return response.data
    }
    return response
  }
)

const regionSlice = createSlice({
  name: "region",
  initialState: {
    regions: [],
    status: null
  },
  extraReducers: {
    [getRegions.pending]: (state, action) => {
      state.status = "loading";
    },
    [getRegions.fulfilled]: (state, action) => {
      state.status = "success";
      state.regions = action.payload;
    },
    [getRegions.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default regionSlice.reducer;
