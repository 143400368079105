import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import { actions } from 'slices/app.slice'
import images from 'assets/images'
import styles from '../dashboard/dashboard.module.scss'
import { path } from '../../utils/const'
import Header from '../../subviews/Header/Header'
import AdminAdvertisers from './AdminAdvertisers'
import AdminHeader from '../../subviews/Header/AdminHeader'
import AdminDrivers from './AdminDrivers'
import AdminSlots from './AdminSlots'
import AdminSettings from './AdminSettings'
import SlotMap from './SlotMap'
import AdminRegions from './AdminRegions'
import AdminCompanies from './AdminCompanies'

const Admin = (props) => {
  const dispatch = useDispatch()
  const { me } = useSelector((state) => state.app)
  const userType = me?.usertype

  const [showBack, setShowBack] = useState(false)

  const profileMenu = () => {
    console.log(props.page)
    if (props.page !== 'home') {
      return <div></div>
    }

    if (userType === 'advertiser') {
      console.log('current page', props.page)

      return (
        <div>
          <div className={styles.nav_item}>
            <a href="/campaigns/">Campaigns</a>
          </div>
          <hr />
          <div className={styles.nav_item}>
            <a href="/campaigns/add/">Add Campaign</a>
          </div>
          <hr />
          <div className={styles.nav_item}>
            <a href="/help/">Help & Support</a>
          </div>
          <hr />
          <div className={styles.nav_item}>
            <a href="/help/">Pause Account</a>
          </div>
        </div>
      )
    }

    return <div></div>
  }

  const adminPage = () => {
    console.log('current page ', props.page)
    if (props.page === 'advertisers') {
      return <AdminAdvertisers />
    }
    if (props.page === 'drivers') {
      return <AdminDrivers />
    }
    if (props.page === 'slots') {
      return <AdminSlots />
    }
    if (props.page === 'regions') {
      return <AdminRegions />
    }
    if (props.page === 'companies') {
      return <AdminCompanies />
    }
    if (props.page === 'settings') {
      return <AdminSettings />
    }

    return <SlotMap />
  }

  return (
    <div className={styles.root}>
      <AdminHeader />

      <div className={`${styles.admin_container}`}>
        <div className={styles.dash_container}>
          <div className={styles.dash_container__admin__left}>
            <div className={styles.title_dashboard}>
              <a href={path.dashboard}>Dashboard</a>
            </div>

            <div>
              <div className={styles.admin_nav_item}>
                <a href={path.admin_advertisers}>Advertisers</a>
              </div>
              <hr />

              <div className={styles.admin_nav_item}>
                <a href={path.admin_drivers}>Drivers</a>
              </div>
              <hr />

              <div className={styles.admin_nav_item}>
                <a href={path.admin_regions}>Regions</a>
              </div>
              <hr />

              <div className={styles.admin_nav_item}>
                <a href={path.admin_companies}>Companies</a>
              </div>
              <hr />

              <div className={styles.admin_nav_item}>
                <a href={path.admin_slots}>Slots</a>
              </div>
              <hr />

              <div className={styles.admin_nav_item}>
                <a href={path.admin_settings}>Settings</a>
              </div>
              <hr />
            </div>
          </div>

          <div className={styles.dash_container__admin__right}>
            {adminPage()}

            {profileMenu()}
          </div>
        </div>
      </div>
    </div>
  )
}

Admin.propTypes = {}
Admin.defaultProps = {}

export default Admin
