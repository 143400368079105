import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CompanyService from '../services/CompanyService'
import { setSelectedCampaign } from './campaign.slice'

export const getCompanies = createAsyncThunk(
  "company/getCompanies",
  async (dispatch, getState) => {
    const response = await CompanyService.getAll();
    if(response.status===200){
      return response.data
    }
    return response
  }
)

export const createCompany = createAsyncThunk(
  "company/create",
  async (data, getState) => {
    const response = await CompanyService.create(data)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const updateCompany = createAsyncThunk(
  "company/update",
  async (data, getState) => {
    const response = await CompanyService.update(data)
    if(response.status===200){
      // return response.data
    }
    // return response
    return data
  }
);

export const deleteCompany = createAsyncThunk(
  "company/delete",
  async (data, getState) => {
    const response = await CompanyService.remove(data)
    return response
  }
);

export const setSelectedCompany = createAsyncThunk(
  "company/select_company",
  async (data, getState) => {
    return data
  }
);

const companySlice = createSlice({
  name: "company",
  initialState: {
    companies: [],
    selectedCompany: null,
    status: null
  },
  extraReducers: {
    [getCompanies.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCompanies.fulfilled]: (state, action) => {
      state.status = "success";
      state.companies = action.payload;
    },
    [getCompanies.rejected]: (state, action) => {
      state.status = "failed";
    },
    [setSelectedCompany.fulfilled]: (state, action) => {
      state.selectedCompany = action.payload
    },

  },
});

export default companySlice.reducer;
