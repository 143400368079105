import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import app from 'slices/app.slice'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import userReducer from 'slices/user.slice'
import regionReducer from 'slices/region.slice'
import campaignReducer from 'slices/campaign.slice'
import vehicleReducer from 'slices/vehicle.slice'
import companyReducer from 'slices/company.slice'
import slotReducer from 'slices/slot.slice'
import settingsReducer from 'slices/settings.slice'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  app,
  users: userReducer,
  regions: regionReducer,
  campaigns: campaignReducer,
  vehicles: vehicleReducer,
  companies: companyReducer,
  slots: slotReducer,
  settings: settingsReducer
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [], //blacklisting a store attribute name, will not persist that store attribute.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/*const store = configureStore({
  reducer: {
    app,
    users: userReducer
  },

  middleware:
    process.env.NODE_ENV === 'production'
      ? [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
        ]
      : [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
          logger,
        ],
})*/

const store = configureStore({
  reducer: persistedReducer,
  // middleware option needs to be provided for avoiding the error. ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  }),
});

export const persistor = persistStore(store);
export default store;
