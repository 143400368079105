import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase, { firestore, baseUrl } from '../utils/firebase'
import UserService from '../services/UserService'
import CampaignService from '../services/CampaignService'

export const getCampaigns = createAsyncThunk(
  "campaigns/getAll",
  async (dispatch, getState) => {
    const response = await CampaignService.getAll()
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const getUserCampaigns = createAsyncThunk(
  "campaigns/getByUser",
  async (userId, getState) => {
    const response = await CampaignService.getByUser(userId)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const createCampaigns = createAsyncThunk(
  "campaigns/create",
  async (data, getState) => {
    const response = await CampaignService.create(data)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const updateCampaigns = createAsyncThunk(
  "campaigns/update",
  async (data, getState) => {
    const response = await CampaignService.update(data)
    if(response.status===200){
      // return response.data
    }
    // return response
    return data
  }
);

export const draftCampaigns = createAsyncThunk(
  "campaigns/draft",
  async (data, getState) => {
    // console.log('video file', data.videoFile)
    // const fileResponse = await CampaignService.uploadVideo(data.videoFile)
    // if(fileResponse){
    //   console.log('uploaded file name', fileResponse)
    //   data.input.url = fileResponse
    // }
    //

    return data
  }
);

export const uploadVideo = createAsyncThunk(
  "campaigns/upload",
  async (data, getState) => {
    const fileResponse = await CampaignService.uploadVideo(data)
    if(fileResponse){
      console.log('uploaded file name', fileResponse)
      data.filename = fileResponse
    }
    return data
  }
);

export const setSelectedCampaign = createAsyncThunk(
  "campaigns/select_campaign",
  async (data, getState) => {
    return data
  }
);

export const pauseCampaign = createAsyncThunk(
  "campaigns/pause",
  async (data, getState) => {
    const response = await CampaignService.pause(data)
    return response
  }
);

export const activateCampaign = createAsyncThunk(
  "campaigns/activate",
  async (data, getState) => {
    const response = await CampaignService.activate(data)
    return response
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaigns/activate",
  async (data, getState) => {
    const response = await CampaignService.remove(data)
    return response
  }
);


const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaigns: [],
    newCampaign: null,
    selectedCampaign: null,
    video: null,
    status: null,
  },
  extraReducers: {
    [getCampaigns.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCampaigns.fulfilled]: (state, action) => {
      state.status = "success";
      state.campaigns = action.payload;
    },
    [getCampaigns.rejected]: (state, action) => {
      state.status = "failed";
    },

    //campaigns by user
    [getUserCampaigns.pending]: (state, action) => {
      state.status = "loading";
    },
    [getUserCampaigns.fulfilled]: (state, action) => {
      state.status = "success";
      state.campaigns = action.payload;
    },
    [getUserCampaigns.rejected]: (state, action) => {
      state.status = "failed";
    },

    [createCampaigns.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [updateCampaigns.fulfilled]: (state, action) => {
      state.status = "success";
      state.selectedCampaign = action.payload
    },
    [draftCampaigns.fulfilled]: (state, action) => {
      state.status = "draft";
      state.newCampaign = action.payload
    },

    [uploadVideo.fulfilled]: (state, action) => {
      state.video = action.payload
    },

    [setSelectedCampaign.fulfilled]: (state, action) => {
      state.selectedCampaign = action.payload
    },

    [pauseCampaign.fulfilled]: (state, action) => {
      state.selectedCampaign = action.payload
    },

    [activateCampaign.fulfilled]: (state, action) => {
      state.selectedCampaign = action.payload
    },
    [deleteCampaign.fulfilled]: (state, action) => {
      state.status = "success";
    },
  },
});

export default campaignSlice.reducer;
