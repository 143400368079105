import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/analytics'
import config from './config'

firebase.initializeApp(config.firebase)
firebase.analytics()

//export const baseUrl = 'http://localhost:8080'
export const baseUrl = 'https://movoapp.mr-geek.com'

export const auth = firebase.auth()
export const storage = firebase.storage().ref()
export const performance = firebase.performance()
export const firestore = firebase.firestore()

export const CAMPAIGN_STATUS_ACTIVE = 1;
export const CAMPAIGN_STATUS_PAUSED = 2;
export const CAMPAIGN_STATUS_DELETED = 3;

export default firebase
