import React, { Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { actions } from 'slices/app.slice'
import { path } from 'utils/const'
import Fallback from 'components/Fallback'
import Spinner from 'components/Spinner'
import Home from './pages/home/home'
import Signup from './pages/auth/Signup'
import SignupDriver from './pages/auth/Signup/SignupDriver'
import SignupAdvertiser from './pages/auth/Signup/SignupAdvertiser'
import LoginPage from './pages/auth/Login/LoginPage'
import Admin from './pages/admin/Admin'
import DriverService from './services/DriverService'
import { getRegions } from './slices/region.slice'

const Auth = React.lazy(() => import('./pages/auth'))
const Dashboard = React.lazy(() => import('./pages/dashboard'))

function Router() {
  const dispatch = useDispatch()
  const { checked, userType } = useSelector((state) => state.app)
  const {me, loggedIn} = useSelector((state) => state.users)
  const {regions} = useSelector((state) => state.regions)

  useEffect(() => {
    console.log('routes me', loggedIn)
    dispatch(actions.authenticate())
    // dispatch(actions.getCompanies())
    dispatch(getRegions())
  }, [])

  if (!checked) {
    return (
      <div className="app-loader-container">
        <Spinner size="4rem" color="white" isLoading />
      </div>
    )
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        {!loggedIn ? (
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/signup/driver">
              <SignupDriver />
            </Route>
            <Route path="/signup/advertiser">
              <SignupAdvertiser />
            </Route>

            <Route path={path.driver_view_update}>
              <DriverService />
            </Route>

            <Route path="/">
              <Home />
            </Route>
            <Redirect to="/" />
          </Switch>
        ) : (
          <Switch>
            <Route path={path.dashboard}>
              <Dashboard page="home" />
            </Route>

            <Route path={path.profile}>
              <Dashboard page="profile" />
            </Route>

            <Route path={path.add_campaign_confirm}>
              <Dashboard page="add_campaign_confirm" />
            </Route>

            <Route path={path.campaign_pay}>
              <Dashboard page="campaign_pay" />
            </Route>

            <Route path={path.add_campaign}>
              <Dashboard page="add_campaign" />
            </Route>

            <Route path={path.edit_campaign}>
              <Dashboard page="edit_campaign" />
            </Route>
            <Route path={path.campaigns}>
              <Dashboard page="campaigns" />
            </Route>

            <Route path={path.single_vehicle}>
              <Dashboard page="single_vehicle" />
            </Route>

            <Route path={path.withdraw_success}>
              <Dashboard page="withdraw_success" />
            </Route>

            <Route path={path.withdraw}>
              <Dashboard page="withdraw" />
            </Route>

            <Route path={path.withdrawal_history}>
              <Dashboard page="withdrawal_history" />
            </Route>

            <Route path={path.stats}>
              <Dashboard page="stats" />
            </Route>

            <Route path={path.admin_advertisers}>
              <Admin page="advertisers" />
            </Route>
            <Route path={path.admin_drivers}>
              <Admin page="drivers" />
            </Route>
            <Route path={path.admin_slots}>
              <Admin page="slots" />
            </Route>
            <Route path={path.admin_regions}>
              <Admin page="regions" />
            </Route>
            <Route path={path.admin_companies}>
              <Admin page="companies" />
            </Route>
            <Route path={path.admin_settings}>
              <Admin page="settings" />
            </Route>
            <Route path={path.admin}>
              <Admin page="admin" />
            </Route>

            <Route path={path.driver_view_update}>
              <Admin page="admin" />
            </Route>
            <Redirect to={me.role === 'ADMIN' ? path.admin : path.dashboard} />
          </Switch>
        )}
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
