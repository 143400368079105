import React, { useCallback, useEffect, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase'
import Alert from 'react-popup-alert'
import Select, { components } from 'react-select'
import CurrencyInput from 'react-currency-input-field'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-popup-alert/dist/index.css' // theme css fil

import stylesDashboard from '../dashboard/dashboard.module.scss'
import { actions } from '../../slices/app.slice'
import { firestore } from '../../utils/firebase'
import Button from '../../components/Button'
import EditVehicle from '../driver/EditVehicle'
import AddSlot from './AddSlot'
import RegionService from '../../services/RegionService'
import EditSlot from './EditSlot'
import AddRegion from './AddRegion'
import EditRegion from './EditRegion'

const AdminRegions = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { me, timeOptions } = useSelector((state) => state.app)
  const [showAddRegion, setShowAddRegion] = useState(false)
  const [editRegion, setEditRegion] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('0')
  const [selectedTimeSlotName, setSelectedTimeSlotName] = useState(
    '00:00 - 01:00',
  )

  const [regions, setRegions] = useState([])
  const [selectedRegions, setSelectedRegions] = useState([])

  const modifyCampaign = async (campaign) => {
    try {
      console.log(campaign)
      dispatch(actions.setSelectedCampaign(campaign))
      history.push(`/campaigns/${campaign.campaignId}`)
    } catch (err) {
      console.error('Error', err)
    }
  }

  useEffect(() => {
    // eslint-disable-next-line radix
    let isSubscribed = true
    loadRegions()
    return () => (isSubscribed = false)
  }, [])

  const loadRegions = () => {
    const regionData = RegionService.getAll()
    regionData
      .then((response) => {
        console.log(response.data)
        setRegions(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        // always executed
      })
    return false
  }
  const deleteRegion = (id) => {
    if (window.confirm('Are you sure?')) {
      const regionData = RegionService.deleteRegion(id)
      loadRegions()
    }
  }

  const regionItem = (doc) => {
    const wData = doc
    return (
      <React.Fragment key={doc.id}>
        <div className={stylesDashboard.adv_item} data-id={wData.id}>
          <div className={stylesDashboard.adv_item_name}>{wData.name}</div>
          <div className={stylesDashboard.adv_item_email}>{wData.latitude}</div>
          <div className={stylesDashboard.adv_item_email}>
            {wData.longitude}
          </div>
          <div className={stylesDashboard.adv_item_email}>{wData.radius}</div>
          <div className={stylesDashboard.data_item_action}>
            <button
              type="button"
              className={`${stylesDashboard.data_item_status} ${stylesDashboard.data_item_edit}`}
              onClick={() => {
                setSelectedRegion(doc)
                setEditRegion(true)
              }}
            >
              Edit
            </button>
            <button
              type="button"
              className={`${stylesDashboard.data_item_status} ${stylesDashboard.data_item_delete}`}
              onClick={() => {
                deleteRegion(wData.id)
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      <div className={stylesDashboard.title_back}>
        <a href="/dashboard/" className={stylesDashboard.back_to_page}>
          &nbsp;
        </a>
        Regions
        <button type="button" onClick={() => setShowAddRegion(true)}>
          Add Regions
        </button>
      </div>

      <div className={stylesDashboard.campaign_list}>
        <div
          className={`${stylesDashboard.data_item} ${stylesDashboard.data_item_th}`}
        >
          <div className={stylesDashboard.adv_item_name}>Name</div>
          <div className={stylesDashboard.adv_item_email}>Latitude</div>
          <div className={stylesDashboard.adv_item_date}>Longitude</div>
          <div className={stylesDashboard.adv_item_date}>Radius</div>
          <div className={stylesDashboard.data_item_action}></div>
        </div>

        {regions && <div>{regions.map((doc) => regionItem(doc))}</div>}
      </div>

      {showAddRegion && (
        <AddRegion
          onClose={() => {
            setShowAddRegion(false)
            loadRegions()
          }}
        />
      )}
      {selectedRegion && editRegion && (
        <EditRegion
          region={selectedRegion}
          onClose={() => {
            setEditRegion(false)
            loadRegions()
          }}
        />
      )}
    </div>
  )
}
AdminRegions.propTypes = {}
AdminRegions.defaultProps = {}

export default AdminRegions
