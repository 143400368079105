import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Input from 'components/Input'
import Button from 'components/Button'
import ErrorBox from 'components/ErrorBox'
import ConfirmEmail from 'subviews/ConfirmEmail'
import validate, { tests } from 'utils/validate'
import { useDispatch } from 'react-redux'
import { actions } from 'slices/app.slice'
import {signupUser} from 'slices/user.slice'
import { path } from 'utils/const'
import styles from './signup.module.scss'
import Select from '../../../components/Select/Select'

const customTests = {
  ...tests,
  name: {
    test: tests.name.test,
    error: 'Please input full name',
  },
}

const Signup = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  // ------------------------------------
  // State
  // ------------------------------------
  const [input, setInput] = useState({
    name: '',
    company: '',
    branch: '',
    tel: '',
    location: '',
    license: '',
    username: '',
    email: '',
    // eslint-disable-next-line react/destructuring-assignment
    roles: props.userType,
    password: '',
    confirmPassword: '',
  })
  const [error, setError] = useState({})
  const [resErr, setResError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false)

  // ------------------------------------
  // Handlers
  // ------------------------------------
  const handleOnChange = ({ target: { name, value } }) => {
    if (name === 'usertype') {
      setInput((prev) => ({ ...prev, [name]: value.value }))
    } else {
      setInput((prev) => ({ ...prev, [name]: value }))
    }

    setError((prev) => ({ ...prev, [name]: '' }))
    setResError('')
  }

  const handleSubmit = async () => {
    // validation
    setInput((prev) => ({ ...prev, usertype: props.userType }))

    const result = validate(input, customTests)
    setError(result.errors)
    if (result.isError) return

    // confirm password
    if (input.password !== input.confirmPassword) {
      setError({
        password: 'Password do not match',
        confirmPassword: 'Password do not match',
      })
      return
    }

    // signup action
    setLoading(true)

    console.log(input)
    try {
      await dispatch(signupUser(input))
      setOpen(true)
      setLoading(false)
      setResError('')
    } catch (err) {
      setResError(err.message)
      setLoading(false)
    }
  }

  const options = [
    { value: 'advertiser', label: 'Advertiser' },
    { value: 'driver', label: 'Driver' },
  ]

  const userType = () => props.userType

  const userFields = () => {
    if (props.userType === 'advertiser') {
      return (
        <div>
          <Input
            label="Company"
            name="company"
            placeholder="Company"
            value={input.company}
            onChange={handleOnChange}
            error={error.company}
          />
          <Input
            label="Branch"
            name="branch"
            placeholder="Branch"
            value={input.branch}
            onChange={handleOnChange}
            error={error.branch}
          />
          <Input
            label="Telephone"
            name="tel"
            placeholder="Telephone"
            value={input.tel}
            onChange={handleOnChange}
            error={error.tel}
          />
          <Input
            label="Location"
            name="location"
            placeholder="Location"
            value={input.location}
            onChange={handleOnChange}
            error={error.location}
          />
        </div>
      )
    }
    return (
      <div>
        <Input
          label="Driver license"
          name="license"
          placeholder="Driver license"
          value={input.license}
          onChange={handleOnChange}
          error={error.license}
        />
      </div>
    )
  }

  return (
    <div className={styles.root}>
      {resErr && <ErrorBox>{resErr}</ErrorBox>}
      <h2 className={styles.title}>Signup {userType()}</h2>

      <Input
        name="usertype"
        type="hidden"
        value={userType()}
        onChange={handleOnChange}
        error={error.usertype}
      />
      <Input
        label="Full Name"
        name="name"
        placeholder="John Doe"
        value={input.name}
        onChange={handleOnChange}
        error={error.name}
      />

      {userFields()}

      {/*<Input
        label="Username"
        name="username"
        placeholder="Username"
        value={input.username}
        onChange={handleOnChange}
        error={error.username}
      />*/}

      <Input
        label="Email"
        name="email"
        placeholder="email@example.com"
        value={input.email}
        onChange={handleOnChange}
        error={error.email}
      />

      <Input
        type="password"
        label="Password"
        name="password"
        placeholder="password1234"
        value={input.password}
        onChange={handleOnChange}
        error={error.password}
      />
      <Input
        type="password"
        label="Confirm Password"
        name="confirmPassword"
        placeholder="password1234"
        value={input.confirmPassword}
        onChange={handleOnChange}
        error={error.confirmPassword}
      />

      <br />
      <Button
        label="Signup"
        className={`btn-black-fill ${styles.submitButton}`}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
      <div className={styles.footerContainer}>
        <div className={styles.textContainer}>
          You have account?{' '}
          <Button
            label="Log in"
            className={styles.linkButton}
            onClick={() => history.push(path.login)}
          />
        </div>
        <div className={styles.textContainer}>
          Forget{' '}
          <Button
            label="Password"
            className={styles.linkButton}
            onClick={() => history.push(path.resetPassword)}
          />
          ?
        </div>
      </div>
      <ConfirmEmail
        email={input.email}
        isOpen={isOpen}
        toggle={() => setOpen((prev) => !prev)}
        onSubmit={() => {
          setOpen((prev) => !prev)
          history.push(path.login)
        }}
      />
    </div>
  )
}

Signup.propTypes = {}
Signup.defaultProps = {}

export default Signup
