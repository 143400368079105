import axios from 'axios'
import firebase, { firestore, baseUrl, CAMPAIGN_STATUS_PAUSED, CAMPAIGN_STATUS_ACTIVE } from '../utils/firebase'


const getAll = () => {
  const response = axios.get(`${baseUrl}/api/campaigns/list`)
  return response
}

const getByUser = (userId) => {
  const response = axios.get(`${baseUrl}/api/campaigns/list/${userId}/`)
  return response
}

const create = async (data) => {
  await axios.post(`${baseUrl}/api/campaigns/create`, data)
}

const update = async (data) => {
  const {id} = data;
  const response = await axios.put(`${baseUrl}/api/campaigns/${id}/update`, data)
  return response
}

const remove = async (id) => {
  const response = await axios.delete(`${baseUrl}/api/campaigns/${id}/delete`)
  return response
}

const pause = async (data) => {
  const {id} = data;
  const response = await axios.put(`${baseUrl}/api/campaigns/${id}/pause`, data)
  return response
}

const activate = async (data) => {
  const {id} = data;
  const response = await axios.put(`${baseUrl}/api/campaigns/${id}/activate`, data)
  // data.status = 1
  return response
}


const uploadVideo = async (input) => {
  const data = new FormData()
  data.append('file', input.video)

  const response = await axios.post(`${baseUrl}/api/campaigns/upload`, data)
  if(response.status===200){
    return response.data.fileName
  }
  return false
}

const CampaignService = {
  getAll,
  getByUser,
  create,
  update,
  remove,
  uploadVideo,
  pause,
  activate
}

export default CampaignService
