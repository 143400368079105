import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RegionService from 'services/RegionService'
import { addDays } from 'date-fns'
import Select, { components } from 'react-select'
import Alert from 'react-popup-alert'
import { useCollection } from 'react-firebase-hooks/firestore'
import styles from '../driver/addvehicle.module.scss'
import stylesDashboard from '../dashboard/dashboard.module.scss'
import userImage from '../../assets/images/user.png'
import Input from '../../components/Input'
import { actions } from '../../slices/app.slice'

const AddRegion = (props) => {
  const dispatch = useDispatch()
  const { onClose, selectedRegions } = props

  const { me } = useSelector((state) => state.app)

  const [alert, setAlert] = useState({
    type: 'error',
    text: 'Region added successfully.',
    show: false,
  })

  const [input, setInput] = useState({
    name: '',
    latitude: '',
    longitude: '',
    radius: 0,
  })

  useEffect(() => {}, [])

  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
  }

  const handleRegionSubmit = async () => {
    setInput((prev) => ({ ...prev, isLoading: false }))
    const response = await RegionService.create(input)
    console.log('response ', response)
    props.onClose()
    setAlert((prev) => ({ ...prev, show: true }))
  }

  const onCloseAlert = () => {
    setAlert({
      type: '',
      text: '',
      show: false,
    })
  }

  return (
    <div className={styles.add_vehicle_modal}>
      <div className={styles.add_vehicle_container}>
        <div className={stylesDashboard.modal_header}>Add Region</div>
        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Name</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="Name"
              name="name"
              value={input.name}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Latitude</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder=""
              name="latitude"
              value={`${input.latitude}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Longitude</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder=""
              name="longitude"
              value={`${input.longitude}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Radius</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="0"
              name="radius"
              type="number"
              value={`${input.radius}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <hr />

        <div className={stylesDashboard.modal_controls}>
          <button
            type="button"
            className={stylesDashboard.btn}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={stylesDashboard.btn}
            onClick={handleRegionSubmit}
          >
            Update
          </button>
        </div>
      </div>

      <Alert
        header="Add Region"
        btnText="Close"
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick="true"
        showBorderBottom="true"
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
      />
    </div>
  )
}

AddRegion.propTypes = {}
AddRegion.defaultProps = {}

export default AddRegion
