import React, { useCallback, useEffect, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase'
import Alert from 'react-popup-alert'
import Select, { components } from 'react-select'
import CurrencyInput from 'react-currency-input-field'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-popup-alert/dist/index.css' // theme css fil
import stylesDashboard from '../dashboard/dashboard.module.scss'
import { actions } from '../../slices/app.slice'
import AddSlot from './AddSlot'
import RegionService from '../../services/RegionService'
import EditSlot from './EditSlot'
import Map from './Map'

const SlotMap = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { me, timeOptions } = useSelector((state) => state.app)
  const [slots, setSlots] = useState([])
  const [regions, setRegions] = useState([])
  const [map, setMap] = useState(null)
  const [centerX, setCenterX] = useState(0.0)
  const [centerY, setCenterY] = useState(0.0)

  const data = [
    {
      id: 1,
      name: 'Park Slope',
      latitude: '40.6710729',
      longitude: '-73.9988001',
    },
    {
      id: 2,
      name: 'Bushwick',
      latitude: '40.6942861',
      longitude: '-73.9389312',
    },
    {
      id: 3,
      name: 'East New York',
      latitude: '40.6577799',
      longitude: '-73.9147716',
    },
  ]

  data[0].circle = {
    radius: 3000,
    options: {
      strokeColor: '#ff0000',
    },
  }

  const onDataChange = (items) => {
    const _slots = []
    const _selectedRegions = []

    let _centerX = 0.0
    let _centerY = 0.0
    let slotCount = 0

    items.forEach((item, index) => {
      const _data = item

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(_data.latitude) && !isNaN(_data.longitude)) {
        _slots.push({
          id: index,
          name: _data.label,
          label: _data.name,
          latitude: `${_data.latitude}`,
          longitude: `${_data.longitude}`,
          circle: {
            radius: _data.radius,
            options: {
              strokeColor: '#ff0000',
            },
          },
        })

        _centerX += parseFloat(_data.latitude)
        _centerY += parseFloat(_data.longitude)

        console.log(_data.latitude, _data.longitude)
        slotCount++
      }

      //_selectedRegions.push(_data.region.value)
    })
    _centerX /= slotCount
    _centerY /= slotCount

    setCenterX(_centerX)
    setCenterY(_centerY)

    setSlots(_slots)
  }

  useEffect(() => {
    // eslint-disable-next-line radix
    let isSubscribed = true;
    loadRegions()
    return () => (isSubscribed = false)

    // const unsubscribe = RegionService.getAll().onSnapshot(onDataChange)
    // return () => unsubscribe()
  }, [])

  const loadRegions = () => {
    const regionData = RegionService.getAll()
    regionData
      .then((response) => {
        console.log(response.data)
        onDataChange(response.data)
        //setRegions(response.data);
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        // always executed
      })
    return false
  }

  const slotItem = (doc) => {
    const wData = doc
    return (
      <React.Fragment key={doc.id}>
        <div className={stylesDashboard.adv_item} data-id={wData.id}>
          <div className={stylesDashboard.adv_item_name}>
            {wData.regionName}
          </div>
          <div className={stylesDashboard.adv_item_email}>
            {wData.viewsPerCharge}
          </div>
          <div className={stylesDashboard.adv_item_email}>
            {wData.advertisersRate}
          </div>
          <div className={stylesDashboard.adv_item_email}>
            {wData.driversRate}
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      <div className={stylesDashboard.map_container}>
        {slots && slots.length > 0 ? (
          <Map
            center={{ lat: centerX, lng: centerY }}
            zoom={11}
            places={slots}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCgY0N55guAqzlcPj-qqfwTp8bl2uQYlJM"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div style={{ height: `800px`, width: '100%' }} />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
SlotMap.propTypes = {}
SlotMap.defaultProps = {}

export default SlotMap
