import React, { useCallback, useEffect, useState } from 'react'
import { addDays, format } from 'date-fns'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase'
import Alert from 'react-popup-alert'
import Select, { components } from 'react-select'
import CurrencyInput from 'react-currency-input-field'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useCollection, useDocument } from 'react-firebase-hooks/firestore'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-popup-alert/dist/index.css' // theme css fil

import stylesDashboard from '../dashboard/dashboard.module.scss'
import { actions } from '../../slices/app.slice'
import { firestore } from '../../utils/firebase'
import Button from '../../components/Button'
import EditVehicle from '../driver/EditVehicle'
import AddSlot from './AddSlot'
import SlotService from '../../services/SlotsService'
import EditSlot from './EditSlot'
import { getAllDrivers } from '../../slices/user.slice'
import { getSlotsByTime } from '../../slices/slot.slice'

const AdminSlots = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { me, timeOptions } = useSelector((state) => state.app)
  const { slots } = useSelector((state) => state.slots)
  const [showAddSlot, setShowAddSlot] = useState(false)
  const [editSlot, setEditSlot] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('0')
  const [selectedTimeSlotName, setSelectedTimeSlotName] = useState(
    '00:00 - 01:00',
  )

  const [selectedRegions, setSelectedRegions] = useState([])

  const handleTimeChange = (options) => {
    const timeSlotId = options.target.value
    const slotName = timeOptions[timeSlotId].label
    setSelectedTimeSlot(timeSlotId)
    setSelectedTimeSlotName(slotName)

    console.log('time slot', `${timeSlotId} - ${slotName}`)
    dispatch(getSlotsByTime(timeSlotId))
  }

  const modifyCampaign = async (campaign) => {
    try {
      console.log(campaign)
      dispatch(actions.setSelectedCampaign(campaign))
      history.push(`/campaigns/${campaign.campaignId}`)
    } catch (err) {
      console.error('Error', err)
    }
  }

  const onDataChange = (items) => {
    const _slots = []
    const _selectedRegions = []

    items.docs.forEach((item) => {
      const { id } = item
      const data = item.data()
      _slots.push({
        id,
        regionId: data.region.value,
        regionName: data.region.label,
        timeSlot: data.timeSlot,
        timeSlotName: data.timeSlotName,
        viewsPerCharge: data.viewsPerCharge,
        driversRate: data.driversRate,
        advertisersRate: data.advertisersRate,
      })

      _selectedRegions.push(data.region.value)
    })

    setSelectedRegions(_selectedRegions)
  }

  useEffect(() => {
    console.log('selected time slot', selectedTimeSlot)
    dispatch(getSlotsByTime(selectedTimeSlot))
  }, [])

  const slotItem = (doc) => {
    const wData = doc
    return (
      <React.Fragment key={doc.id}>
        <div className={stylesDashboard.adv_item} data-id={wData.id}>
          <div className={stylesDashboard.adv_item_name}>
            {wData.region.name}
          </div>
          <div className={stylesDashboard.adv_item_email}>
            {wData.viewsPerCharge}
          </div>
          <div className={stylesDashboard.adv_item_email}>
            {wData.advertisersRate}
          </div>
          <div className={stylesDashboard.adv_item_email}>
            {wData.driversRate}
          </div>
          <div className={stylesDashboard.data_item_action}>
            <button
              type="button"
              className={`${stylesDashboard.data_item_status} ${stylesDashboard.data_item_edit}`}
              onClick={() => {
                setSelectedSlot(wData)
                setEditSlot(true)
              }}
            >
              Edit
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      <div className={stylesDashboard.title_back}>
        <a href="/dashboard/" className={stylesDashboard.back_to_page}>
          &nbsp;
        </a>
        Slots
        <button type="button" onClick={() => setShowAddSlot(true)}>
          Add Slot
        </button>
      </div>

      <div>
        Select time slot &nbsp;&nbsp;&nbsp;
        <select onChange={(e) => handleTimeChange(e)} value={selectedTimeSlot}>
          {timeOptions.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <hr />

      <div className={stylesDashboard.campaign_list}>
        <div
          className={`${stylesDashboard.data_item} ${stylesDashboard.data_item_th}`}
        >
          <div className={stylesDashboard.adv_item_name}>Region</div>
          <div className={stylesDashboard.adv_item_email}>Views/Charge</div>
          <div className={stylesDashboard.adv_item_date}>Advertiser Rate</div>
          <div className={stylesDashboard.adv_item_date}>Driver Rate</div>
          <div className={stylesDashboard.data_item_action}></div>
        </div>

        {slots && <div>{slots.map((doc) => slotItem(doc))}</div>}
      </div>

      {showAddSlot && (
        <AddSlot
          timeSlot={selectedTimeSlot}
          timeSlotName={selectedTimeSlotName}
          selectedRegions={selectedRegions}
          onClose={() => setShowAddSlot(false)}
        />
      )}
      {selectedSlot && editSlot && (
        <EditSlot slot={selectedSlot} timeSlotId={selectedTimeSlot} timeSlotName={selectedTimeSlotName} onClose={() => setEditSlot(false)} />
      )}
    </div>
  )
}
AdminSlots.propTypes = {}
AdminSlots.defaultProps = {}

export default AdminSlots
