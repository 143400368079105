import axios from 'axios'
import firebase, { firestore, baseUrl } from '../utils/firebase'

const db = firestore.collection('regions')

const getAll = () => {
  const response = axios.get(`${baseUrl}/api/regions/list`)
  return response
}

const create = async (data) => {
  await axios.post(`${baseUrl}/api/regions/create`, data)
}

const update = async (id, data) => {
  const response = await axios.put(`${baseUrl}/api/regions/${id}/update`, data)
  return response
}

const remove = async (id) => {
  const response = await axios.delete(`${baseUrl}/api/regions/${id}/delete`)
  return response
}

const RegionService = {
  getAll,
  create,
  update,
  remove,
}

export default RegionService
