import axios from 'axios'
import firebase, { firestore, baseUrl } from '../utils/firebase'

const getAll = () => {
  const response = axios.get(`${baseUrl}/api/slots/list`)
  return response
}
const getByTime = (time) => {
  const response = axios.get(`${baseUrl}/api/slots/${time}/list`)
  return response
}

const create = async (data) => {
  await axios.post(`${baseUrl}/api/slots/create`, data)
}

const update = async (data) => {
  const {id} = data
  const response = await axios.put(`${baseUrl}/api/slots/${id}/update`, data)
  return response
}

const remove = async (id) => {
  const response = await axios.delete(`${baseUrl}/api/slots/${id}/delete`)
  return response
}

const SlotService = {
  getAll,
  getByTime,
  create,
  update,
  remove,
}

export default SlotService
