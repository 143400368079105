import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase, { firestore, baseUrl } from '../utils/firebase'
import UserService from '../services/UserService'
import CampaignService from '../services/CampaignService'
import VehicleService from '../services/VehicleService'

export const getVehicles = createAsyncThunk(
  "vehicles/getVehicles",
  async (dispatch, getState) => {
    const response = await VehicleService.getAll()
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const getUserVehicles = createAsyncThunk(
  "vehicles/getByUser",
  async (userId, getState) => {
    const response = await VehicleService.getByUser(userId)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const createVehicle = createAsyncThunk(
  "vehicles/create",
  async (data, getState) => {
    const response = await VehicleService.create(data)
    if(response.status===200){
      return response.data
    }
    return response
  }
);

export const updateVehicle = createAsyncThunk(
  "vehicles/update",
  async (data, getState) => {
    const response = await VehicleService.update(data)
    if(response.status===200){
      // return response.data
    }
    // return response
    return data
  }
);

export const draftVehicle = createAsyncThunk(
  "vehicles/draft",
  async (data, getState) => {
    return data
  }
);

export const setSelectedVehicle = createAsyncThunk(
  "vehicles/select_vehicle",
  async (data, getState) => {
    return data
  }
);

export const pauseVehicle = createAsyncThunk(
  "vehicles/pause",
  async (data, getState) => {
    const response = await VehicleService.pause(data)
    return response
  }
);

export const activateVehicle = createAsyncThunk(
  "vehicles/activate",
  async (data, getState) => {
    const response = await VehicleService.activate(data)
    return response
  }
);

export const deleteVehicle = createAsyncThunk(
  "vehicles/delete",
  async (data, getState) => {
    const response = await VehicleService.remove(data)
    return response
  }
);


const vehicleSlice = createSlice({
  name: "vehicle",
  initialState: {
    vehicles: [],
    newVehicle: null,
    selectedVehicle: null,
    status: null,
  },
  extraReducers: {
    [getVehicles.pending]: (state, action) => {
      state.status = "loading";
    },
    [getVehicles.fulfilled]: (state, action) => {
      state.status = "success";
      state.vehicles = action.payload;
    },
    [getVehicles.rejected]: (state, action) => {
      state.status = "failed";
    },

    //vehicle by user
    [getUserVehicles.pending]: (state, action) => {
      state.status = "loading";
    },
    [getUserVehicles.fulfilled]: (state, action) => {
      state.status = "success";
      state.vehicles = action.payload;
    },
    [getUserVehicles.rejected]: (state, action) => {
      state.status = "failed";
    },

    [createVehicle.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [updateVehicle.fulfilled]: (state, action) => {
      state.status = "success";
      state.selectedVehicle = action.payload
    },
    [draftVehicle.fulfilled]: (state, action) => {
      state.status = "draft";
      state.newVehicle = action.payload
    },

    [setSelectedVehicle.fulfilled]: (state, action) => {
      state.selectedVehicle = action.payload
    },

    [pauseVehicle.fulfilled]: (state, action) => {
      state.selectedCampaign = action.payload
    },

    [activateVehicle.fulfilled]: (state, action) => {
      state.selectedCampaign = action.payload
    },
    [deleteVehicle.fulfilled]: (state, action) => {
      state.status = "success";
    },
  },
});

export default vehicleSlice.reducer;
