import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDays } from 'date-fns'
import Select, { components } from 'react-select'
import Alert from 'react-popup-alert'
import { useCollection } from 'react-firebase-hooks/firestore'
import styles from '../driver/addvehicle.module.scss'
import stylesDashboard from '../dashboard/dashboard.module.scss'
import userImage from '../../assets/images/user.png'
import Input from '../../components/Input'
import { actions } from '../../slices/app.slice'
import { createSlot, getSlotsByTime, updateSlot } from '../../slices/slot.slice'

const EditSlot = (props) => {
  const dispatch = useDispatch()
  const { onClose, timeSlotId, timeSlotName, slot } = props

  const {
    me,
    companiesList,
    timeOptions,

    regionList,
  } = useSelector((state) => state.app)

  const [alert, setAlert] = useState({
    type: 'error',
    text: 'Vehicle added successfully.',
    show: false,
  })

  const [input, setInput] = useState({
    id: slot.id,
    viewsPerCharge: slot.viewsPerCharge,
    driversRate: slot.driversRate,
    advertisersRate: slot.advertisersRate,
  })

  useEffect(() => {
    console.log('slot', slot)
  }, [])

  const handleOnChange = ({ target: { name, value } }) => {
    setInput((prev) => ({ ...prev, [name]: value }))
  }
  const handleRegionChange = (options) => {
    setInput((prev) => ({ ...prev, region: options }))
  }

  const handleCommissionedChange = (options) => {
    setInput((prev) => ({ ...prev, commissionedBy: options }))
  }

  const handleTimeChange = (options) => {
    setInput((prev) => ({ ...prev, preferredTimes: options }))
  }

  const regionPlaceholder = (_props) => <components.Placeholder {..._props} />

  const handleSlotSubmit = async () => {
    try {
      console.log(input)
      //dispatch(actions.updateSlot(input))

      await dispatch(updateSlot(input))
      dispatch(getSlotsByTime(timeSlotId))

      setInput((prev) => ({ ...prev, isLoading: false }))
      props.onClose()
      setAlert((prev) => ({ ...prev, show: true }))
    } catch (err) {
      console.error('Error', err)
      setInput((prev) => ({ ...prev, isLoading: false }))
    }
  }

  const onCloseAlert = () => {
    setAlert({
      type: '',
      text: '',
      show: false,
    })
  }

  return (
    <div className={styles.add_vehicle_modal}>
      <div className={styles.add_vehicle_container}>
        <div className={stylesDashboard.modal_header}>Edit Slot</div>
        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Time slot</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="Time slot"
              name="timeSlot"
              readOnly
              value={timeSlotName}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Region</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="Time slot"
              name="timeSlot"
              readOnly
              value={input.regionName}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Views per charge</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="0"
              name="viewsPerCharge"
              value={`${input.viewsPerCharge}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Drivers Rate</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="0"
              name="advertisersRate"
              value={`${input.advertisersRate}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className={stylesDashboard.form_field}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.field_label}>Advertisers Rate</label>
          <div className="field_value">
            <Input
              className="field-b-b"
              placeholder="0"
              name="driversRate"
              type="number"
              value={`${input.driversRate}`}
              onChange={handleOnChange}
            />
          </div>
        </div>

        <hr />

        <div className={stylesDashboard.modal_controls}>
          <button
            type="button"
            className={stylesDashboard.btn}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={stylesDashboard.btn}
            onClick={handleSlotSubmit}
          >
            Update
          </button>
        </div>
      </div>

      <Alert
        header="Updated Vehicle"
        btnText="Close"
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick="true"
        showBorderBottom="true"
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
      />
    </div>
  )
}

EditSlot.propTypes = {}
EditSlot.defaultProps = {}

export default EditSlot
